import React from "react"
import { generateFormField } from "../../Elements/Form/services/form"

const DiagnosisFollowUpQuestions = ({
  values,
  setFieldValue,
  followUpQuestions,
}) => {
  if (followUpQuestions?.length > 0)
    return followUpQuestions?.map((followUpQuestion) => {
      if (
        values?.whyMedicineWasPrescribed?.value ===
        followUpQuestion.referenceAnswer
      )
        return (
          <div className="notification is-light ml-2 pr-0-mobile mt-1">
            {generateFormField({
              formField: { ...followUpQuestion },
              formFields: followUpQuestions,
              values: values,
              setFieldValue,
            })}
          </div>
        )
    })
  return null
}

export default DiagnosisFollowUpQuestions
