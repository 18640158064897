import { useStaticQuery, graphql } from "gatsby"

const ALL_DISCOUNT_COUPONS = graphql`
  query DiscountCoupons {
    allDiscountCoupons {
      nodes {
        activation
        alternatingQuantity
        code
        created
        company
        discountType
        percentOff
        freeProductInclusions {
          quantity
          type
          title
        }
        id
        includeConvenienceFee
        key
        notes
        productInclusions {
          title
          type
        }
        websiteDescription
      }
    }
  }
`

export const useDiscountCoupons = () => {
  const data = useStaticQuery(ALL_DISCOUNT_COUPONS)

  return data
}
