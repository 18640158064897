import React from "react"
import FormRadio from "../../Elements/Form/FormRadio"
import {
  getCustomerTypeLabel,
  getCustomerTypeOptions,
} from "../services/customerTypes"

const CustomerTypeOptions = ({
  values,
  calculatorDispatch,
  isSpecialOrder,
  medicine,
}) => {
  const customerTypeLabel = getCustomerTypeLabel({ medicine, isSpecialOrder })
  const customerTypeOptions = getCustomerTypeOptions({ medicine })

  return (
    <div className="has-text-left">
      <FormRadio
        isInline
        hideOptional
        title={customerTypeLabel}
        name="customerType"
        options={customerTypeOptions}
        value={values?.customerType}
        onChange={(event) =>
          calculatorDispatch({
            type: "SET_CUSTOMER_TYPE",
            payload: event?.target?.value,
          })
        }
        disabled={customerTypeOptions?.length === 1}
      />
    </div>
  )
}

export default CustomerTypeOptions
