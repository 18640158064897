const SET_IS_LOADING = "SET_IS_LOADING"

export const discount = {
  customerType: "SET_CUSTOMER_TYPE",
  type: "SET_DISCOUNT_TYPE",
  tier: "SET_DISCOUNT_TIER",
  queue: "SET_DISCOUNT_QUEUE",
  values: "SET_DISCOUNT_VALUES",
}

export const medicine = {
  quantity: "SET_MEDICINE_QUANTITY",
  maxQuantity: "SET_MAX_QUANTITY",
}

export const initialState = ({ customerTypeOptions }) => {
  return {
    medicineQuantity: 0,
    maxQuantity: 10,
    customerType:
      customerTypeOptions?.length === 1 ? customerTypeOptions[0] : "",
    discountType: "",
    discountTier: "",
    discountQueue: null,
    discountValues: [],
    isLoading: false,
  }
}

export let AssistanceCalculatorReducer = (state, action) => {
  switch (action.type) {
    case medicine.quantity:
      return { ...state, medicineQuantity: action.payload }
    case medicine.maxQuantity:
      return { ...state, maxQuantity: action.payload }
    case discount.customerType:
      return { ...state, customerType: action.payload }
    case discount.type:
      return { ...state, discountType: action.payload }
    case discount.tier:
      return { ...state, discountTier: action.payload }
    case discount.queue:
      return { ...state, discountQueue: action.payload }
    case discount.values:
      return { ...state, discountValues: action.payload }
    case SET_IS_LOADING:
      return { ...state, isLoading: action.payload }
    default:
      return state
  }
}
