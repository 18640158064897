import React from "react"
import DiscountTypeOptions from "./DiscountTypeOptions"
import CustomerTypeOptions from "./CustomerTypeOptions"
import DiscountTierOptions from "./DiscountTierOptions"

const SelectCustomerDiscountType = ({
  hasSCPWDDiscountInCoupon,
  hasTierDiscountInCoupon,
  values,
  isSpecialOrder,
  medicine,
  calculatorDispatch,
}) => {
  if (hasTierDiscountInCoupon)
    return (
      <DiscountTierOptions
        values={values}
        calculatorDispatch={calculatorDispatch}
        isSpecialOrder={isSpecialOrder}
        medicine={medicine}
      />
    )
  else if (hasSCPWDDiscountInCoupon)
    return (
      <DiscountTypeOptions
        values={values}
        calculatorDispatch={calculatorDispatch}
        isSpecialOrder={isSpecialOrder}
      />
    )
  return (
    <CustomerTypeOptions
      values={values}
      calculatorDispatch={calculatorDispatch}
      isSpecialOrder={isSpecialOrder}
      medicine={medicine}
    />
  )
}

export default SelectCustomerDiscountType
