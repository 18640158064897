import { useStaticQuery, graphql } from "gatsby"

const useAssistanceCalculatorImages = () => {
  const data = useStaticQuery(graphql`
    {
      medicines: file(
        relativePath: { eq: "calculator_instruction_medicine.png" }
      ) {
        childImageSharp {
          fixed(width: 48) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return data
}

export default useAssistanceCalculatorImages
