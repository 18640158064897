import React from "react"
import classNames from "classnames"

import Container from "./Container"
import ActionButtons from "../Elements/ActionButtons"

import styles from "./utils/layout.module.scss"

const CartLayout = (props) => (
  <Container {...props}>
    <div
      className={classNames("columns is-centered mb-5", styles["container"])}
    >
      <div className="column pb-0-mobile is-7">{props.children}</div>
      <div
        className={classNames("column", {
          "is-hidden-mobile": !props.showCartInMobile,
          [styles["cart__containerIsHiddenTablet"]]: !props.showCartInTablet,
        })}
      >
        {props.cartContainer}
      </div>
    </div>
    <ActionButtons {...props} />
  </Container>
)

export default CartLayout
