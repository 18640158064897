import React from "react"
import FormRadio from "../../Elements/Form/FormRadio"
import {
  getDiscountTier,
  getDiscountTierLabel,
  getDiscountTierOptions,
} from "../services/customerTypes"

const DiscountTierOptions = ({
  values,
  calculatorDispatch,
  isSpecialOrder,
  medicine,
}) => {
  const discountTierOptions = getDiscountTierOptions({ medicine })
  const discountTierLabel = getDiscountTierLabel({ medicine, isSpecialOrder })
  return (
    <div className="has-text-left">
      <FormRadio
        isInline
        hideOptional
        title={discountTierLabel}
        name="discountTier"
        options={discountTierOptions}
        value={values?.discountTier}
        onChange={(event) =>
          calculatorDispatch({
            type: "SET_DISCOUNT_TIER",
            payload: getDiscountTier({
              medicine,
              selectedOption: event?.target?.value,
            }),
          })
        }
      />
    </div>
  )
}

export default DiscountTierOptions
