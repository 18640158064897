import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons"

import AssistanceCalculatorRow from "./AssistanceCalculatorRow"

import { generateTotalMedsToPay } from "../../services/cart"
import { generatePrice, formatPrice } from "../../services/computations"

const AssistanceCalculatorBody = ({
  medicine,
  quantity,
  discountValues,
}) => {
  const totalMedsToPay = generateTotalMedsToPay({
    medicine,
    quantity,
  })
  const purchaseQuantity = formatPrice({
    priceString: (generatePrice(medicine) * totalMedsToPay).toFixed(2),
  })
  const unitPrice = formatPrice({ priceString: generatePrice(medicine) })

  return (
    <Fragment>
      <AssistanceCalculatorRow
        unitPrice={unitPrice}
        quantity={totalMedsToPay}
        headerLeft={"Purchase Quantity"}
        headerRight={`P${purchaseQuantity}`}
      />
      {discountValues.map((discountValue) => {
        let freeGoodsHeader = discountValue?.freeProduct
        let discountAmount = formatPrice({
          priceString: discountValue?.amount?.toFixed(2),
        })

        if (discountValue?.amount)
          return (
            <AssistanceCalculatorRow
              headerLeft={
                <span>
                  {discountValue?.code?.startsWith("SCPWD")
                    ? "SCPWD Discount"
                    : `${
                        parseFloat(discountValue?.percentOff) % 1 === 0
                          ? parseFloat(discountValue?.percentOff).toFixed(0)
                          : parseFloat(discountValue?.percentOff).toFixed(2)
                      }% Discount`}{" "}
                  <span
                    className="tooltip is-tooltip-bottom is-tooltip-multiline"
                    data-tooltip={discountValue?.notes}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </span>
              }
              headerRight={`(P${discountAmount})`}
              className="mt-2"
            />
          )
        else if (discountValue?.quantity)
          return (
            <AssistanceCalculatorRow
              unitPrice={"0.00"}
              quantity={discountValue?.quantity}
              headerLeft={
                <span>
                  {freeGoodsHeader}{" "}
                  <span
                    className="tooltip is-tooltip-bottom is-tooltip-multiline"
                    data-tooltip={discountValue?.notes}
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </span>
                </span>
              }
              headerRight={"P0.00"}
              className="mt-2"
            />
          )
        return null
      })}
    </Fragment>
  )
}

export default AssistanceCalculatorBody
