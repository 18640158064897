import React from "react"
import { generatePrice, formatPrice } from "../services/computations"

const TotalPriceInfo = ({ medicine, qty, discountValues }) => {
  const totalPrice = formatPrice({
    priceString: (
      generatePrice(medicine) * qty -
      (discountValues?.reduce(
        (totalValue, currentValue) => totalValue + currentValue?.amount || 0,
        0
      ) || 0)
    ).toFixed(2),
  })

  return (
    <p className="has-text-right">
      Total:{" "}
      <span className="has-text-primary has-text-weight-bold">
        P{totalPrice}
      </span>
    </p>
  )
}

export default TotalPriceInfo
