import React from "react"
import classNames from "classnames"

import styles from "../../utils/epharmacy.module.scss"

const AssistanceCalculatorRow = ({
  unitPrice,
  quantity,
  headerLeft,
  headerRight,
  className,
}) => (
  <div className={classNames("mx-1 has-text-black", className || "")}>
    <p
      className={classNames(
        "is-size-6 has-text-weight-bold is-flex",
        styles["calculator__rowHeader"]
      )}
    >
      <span>{headerLeft}</span>
      <span>{headerRight}</span>
    </p>
    {unitPrice && <p className="is-size-6">Unit price: P{unitPrice}</p>}
    {quantity && <p className="is-size-6">Qty: {quantity}</p>}
  </div>
)

export default AssistanceCalculatorRow
