import React, { Fragment } from "react"
import RxIndicator from "../../Elements/RxIndicator"

const MedicineInfo = ({ medicine }) => (
  <Fragment>
    <h5 className="has-text-primary mt-0">
      {medicine?.productTitle?.split("(")?.map((item, index) => {
        if (index)
          return (
            <Fragment>
              <br />
              {`(${item}`}
            </Fragment>
          )
        return item
      })}
      <RxIndicator />
    </h5>
  </Fragment>
)

export default MedicineInfo
