import React from "react"

import AssistanceCalculatorBody from "./AssistanceCalculator/AssistanceCalculatorBody"
import AssistanceCalculatorHeader from "./AssistanceCalculator/AssistanceCalculatorHeader"

const AssistanceCalculator = ({
  medicine,
  quantity,
  discountValues,
}) => (
  <div className="notification is-light has-text-left mb-0 px-1">
    <AssistanceCalculatorHeader discountValue={discountValues[0]} />
    <hr className="has-background-white" />
    <AssistanceCalculatorBody
      medicine={medicine}
      quantity={quantity}
      discountValues={discountValues}
    />
  </div>
)

export default AssistanceCalculator
