import api from "../../../services/api"
import { generatePrice } from "./computations"
import { isFirstTimeCustomerType } from "./customerTypes"

export const getProductDiscount = ({ coupons, product }) =>
  coupons.filter(
    (coupon) => coupon?.productInclusions[0]?.title === product.productTitle
  )[0]

export const getDiscountValue = async ({ product, quantity }) => {
  return api.post(`coupons/compute/`, {
    company: "J&J",
    product: {
      ...product,
      qty: parseInt(quantity),
      unitPrice: generatePrice(product),
    },
  })
}

export const hasSCPWDDiscount = ({ medicine, discountCoupons }) => {
  const hasSCPWDDiscountInCoupon = discountCoupons.filter((discountCoupon) => {
    let containsProductInCoupon = discountCoupon?.productInclusions?.filter(
      (product) => product?.title === medicine?.productTitle
    ).length

    return containsProductInCoupon && discountCoupon?.code?.startsWith("SCPWD")
  }).length

  return hasSCPWDDiscountInCoupon
}

export const filterDiscountValues = ({
  discountValues,
  customerType,
  discountType,
  discountTier,
  isSpecialOrder,
}) =>
  discountValues
    ?.filter((discount) => {
      if (discountTier) return discount?.code?.includes(discountTier)
      else if (customerType) {
        if (isFirstTimeCustomerType({ customerType }))
          return discount?.isInitiation
        return !discount?.isInitiation
      } else if (discountType) {
        if (discountType === "SCPWD") return discount?.code?.startsWith("SCPWD")
        return !discount?.code?.startsWith("SCPWD")
      }
    })
    .filter((discount) => {
      let isSpecialCouponOnly = discount?.code?.includes("SPECIAL")
      let isRegularCouponOnly = discount?.code?.includes("REGULAR")

      if (isSpecialCouponOnly) return isSpecialOrder
      else if (isRegularCouponOnly) return !isSpecialOrder

      return true
    })

export const calculateDiscountValue = ({
  medicine,
  calculatorState,
  calculatorDispatch,
  isSpecialOrder,
}) => {
  const {
    customerType,
    discountType,
    discountTier,
    medicineQuantity,
    maxQuantity,
  } = calculatorState
  getDiscountValue({
    product: medicine,
    quantity: medicineQuantity,
  }).then((response) => {
    const finalDiscountValue =
      response?.data?.length > 1
        ? filterDiscountValues({
            discountValues: response?.data,
            customerType,
            discountType,
            discountTier,
            isSpecialOrder,
          })
        : response?.data
    calculatorDispatch({
      type: "SET_DISCOUNT_VALUES",
      payload: finalDiscountValue,
    })
    calculatorDispatch({ type: "SET_IS_LOADING", payload: false })
    const hasDailyUseLimit = finalDiscountValue?.filter(
      (discountValue) => discountValue?.dailyUseLimit
    )
    if (hasDailyUseLimit.length > 0)
      calculatorDispatch({
        type: "SET_MEDICINE_QUANTITY",
        payload: hasDailyUseLimit[0].dailyUseLimit,
      })

    const totalUseLimit = finalDiscountValue[0]?.totalUseLimit
    calculatorDispatch({
      type: "SET_MAX_QUANTITY",
      payload:
        totalUseLimit && totalUseLimit <= maxQuantity ? totalUseLimit : 10,
    })
  })
}
