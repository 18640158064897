import React, { useContext } from "react"
import classNames from "classnames"

import CollapsibleCard from "../Elements/CollapsibleCard"
import DocumentsCardRow from "./DocumentsCard/DocumentsCardRow"

import styles from "./utils/cart.module.scss"
import { AppContext } from "../../context/AppContext"
import specialOrderRxUploadGuidelines from "./utils/specialOrderRxUploadGuidelines.json"

const SpecialOrderRxCardBody = ({ documents, handleDeleteDocument }) => {
  if (documents?.length > 0)
    return (
      <div className="mt-1">
        {documents.map((file, index) => (
          <DocumentsCardRow
            fileName={file.name}
            oldFileName={file.oldname}
            index={index}
            handleDeleteDocument={handleDeleteDocument}
            file={file}
          />
        ))}
      </div>
    )
  else
    return (
      <div className={classNames("mt-1", styles["cart__emptyDocumentList"])}>
        <h5 className="title mt-1 is-size-5 has-text-left">
          Upload Guidelines
        </h5>
        <ol className="has-text-left mr-1 ml-2">
          {specialOrderRxUploadGuidelines?.map((guideline) => (
            <li className="mb-1 has-text-grey">{guideline}</li>
          ))}
        </ol>
      </div>
    )
}

const SpecialOrderRxCard = () => {
  const { state, dispatch } = useContext(AppContext)
  const { documents } = state
  const handleDeleteDocument = (index) => {
    const documentsList = [...documents]
    documentsList.splice(index, 1)
    dispatch({
      type: "SAVE_DOCUMENTS",
      payload: [...documentsList],
    })
  }

  return (
    <CollapsibleCard
      title={`Prescriptions Uploaded (${documents.length})`}
      color="white"
      isOpen
    >
      <SpecialOrderRxCardBody
        documents={documents}
        handleDeleteDocument={handleDeleteDocument}
      />
    </CollapsibleCard>
  )
}

export default SpecialOrderRxCard
