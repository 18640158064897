import React from "react"
import RxIndicator from "../../Elements/RxIndicator"
import {
  generatePrice,
  formatPrice,
} from "../../Epharmacy/services/computations"

const SearchResultRow = ({ result, handleClick }) => {
  const unitPrice = formatPrice({ priceString: generatePrice(result) })

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <li onClick={() => handleClick()} onKeyDown={() => handleClick()}>
      <div className="is-flex has-text-weight-bold">
        <div>{result.productTitle}</div>
        <RxIndicator />
      </div>
      <div className="is-size-7 mt-1">SRP: P{unitPrice}</div>
    </li>
  )
}

export default SearchResultRow
