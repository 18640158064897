import React, { useState, useEffect, Fragment } from "react"
import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSearch } from "@fortawesome/free-solid-svg-icons"
import { fuzzySearch } from "./services/fuzzySearch"
import SearchResults from "./SearchResults"

const Search = ({
  label,
  isRequired,
  type,
  name,
  placeholder,
  withResults,
  dataSource,
  onSelect,
  isMobile,
  helper,
}) => {
  const [searchQuery, setSearchQuery] = useState("")
  const [searchQueue, setSearchQueue] = useState(null)
  const [searchResults, setSearchResults] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setSearchResults([])
    if (searchQuery) {
      setIsLoading(true)
      switch (dataSource.type) {
        case "api":
          break
        case "graphql":
          if (searchQueue) clearTimeout(searchQueue)
          setSearchQueue(
            setTimeout(async () => {
              await setSearchResults(
                fuzzySearch(searchQuery, dataSource.data, dataSource.lookupKeys)
              )
              setIsLoading(false)
            }, 1000)
          )
          break
        default:
          break
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery])

  const handleValidateMatchDistance = () => {
    return searchResults.length > 0 && searchResults[0].distance !== 1
  }

  return (
    <Fragment>
      <div className="field">
        {!!label && (
          <label
            className={classNames("label", {
              "has-text-weight-normal has-text-grey-dark": !isRequired,
            })}
          >
            {label}
            {!!isRequired && <span className="has-text-danger"> *</span>}
            {!!helper?.message && (
              <span
                className={classNames(
                  "help has-text-weight-normal",
                  helper?.className || ""
                )}
              >
                {helper?.message}
              </span>
            )}
          </label>
        )}
        <div className="field-body">
          <div className="field">
            <p
              className={classNames("control has-icons-left", {
                "is-loading": isLoading,
              })}
            >
              <input
                type={type}
                className="input"
                name={name}
                placeholder={placeholder}
                value={searchQuery}
                onChange={(event) => {
                  setSearchQuery(event.target.value)
                }}
                readOnly={isMobile}
              />

              <span className={classNames("icon is-small is-left")}>
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </p>
          </div>
        </div>
      </div>
      <SearchResults
        searchResults={searchResults}
        isExactMatch={handleValidateMatchDistance()}
        onSelect={onSelect}
        setSearchResults={setSearchResults}
        setSearchQuery={setSearchQuery}
      />
    </Fragment>
  )
}

export default Search
