import React, { useContext } from "react"

import MedicinesCard from "../../Cart/MedicinesCard"
import SpecialOrderRxCard from "../../Cart/SpecialOrderRxCard"
import OrderSubtotalSection from "../../Cart/OrderSubtotalSection"

import { AppContext } from "../../../context/AppContext"
import { getCouponDiscountAmount } from "../services/coupon"
import { generateDeliveryFee } from "../services/computations"

const SpecialOrderCart = ({ handleDeleteMedicineCallback }) => {
  const { state } = useContext(AppContext)
  const { epharmacy } = state
  const { medicines } = epharmacy

  return (
    <div className="mt-2">
      <SpecialOrderRxCard documents={[]} />
      <MedicinesCard
        hideAddMoreMedsButton
        handleDeleteMedicineCallback={handleDeleteMedicineCallback}
      />
      {medicines.length > 0 && (
        <OrderSubtotalSection
          medicines={medicines}
          couponDiscount={getCouponDiscountAmount(
            null,
            medicines,
            generateDeliveryFee()
          )}
        />
      )}
    </div>
  )
}

export default SpecialOrderCart
