import React from "react"
import FormRadio from "../../Elements/Form/FormRadio"

const DiscountTypeOptions = ({
  values,
  calculatorDispatch,
  isSpecialOrder,
}) => {
  return (
    <div className="has-text-left">
      <FormRadio
        isInline
        hideOptional
        title={`${
          isSpecialOrder ? "Your patient" : "You"
        } would like to receive:`}
        name="discountType"
        options={["SCPWD", "Buy 6, Get 2"]}
        value={values?.discountType}
        onChange={(event) =>
          calculatorDispatch({
            type: "SET_DISCOUNT_TYPE",
            payload: event?.target?.value,
          })
        }
      />
    </div>
  )
}

export default DiscountTypeOptions
