import React, { useContext, useState, useEffect } from "react"
import { navigate, Link } from "gatsby"
import { Formik, Form } from "formik"

import Search from "../../Search/Search"
import CartLayout from "../../Layout/CartLayout"
import SpecialOrderCart from "./SpecialOrderCart"
import UploadPrescription from "./UploadPrescription"
import FormInput from "../../Elements/Form/FormInput"
import FormSelect from "../../Elements/Form/FormSelect"
import AddMedicineModal from "../Order/AddMedicineModal"
import DiagnosisFollowUpQuestions from "./DiagnosisFollowUpQuestions"

import {
  specialOrderValidationSchema,
  specialOrderPatientInitialValues,
} from "../utils/formData"
import {
  FOLLOWUP_QUESTION_NAMES,
  specialOrderPrescribedMedicineChoices,
} from "../../Enrollment/utils/formData"
import { AppContext } from "../../../context/AppContext"
import { isMedicineAlreadyInCart } from "../services/cart"
import { useEpharmacyProducts } from "../hooks/useEpharmacyProducts"

const InputPatientDetails = ({ locationState }) => {
  const { state, dispatch } = useContext(AppContext)
  const { epharmacy, documents } = state
  const { medicines } = epharmacy
  const data = useEpharmacyProducts()
  const [diagnoses, setDiagnoses] = useState([])
  const [followUpQuestions, setFollowUpQuestions] = useState([])

  const noPrescriptionMessage = !documents.length && (
    <div className="message is-danger">
      <div className="message-body">
        You must upload at least one prescription to proceed.
      </div>
    </div>
  )

  const addMedicineToCart = async (item, qty, discountValues) => {
    let medicineList = [...state.epharmacy.medicines]
    medicineList.push({ ...item, qty: qty, discountValues })
    await dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        epharmacy: { ...state.epharmacy, medicines: medicineList },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: `Added ${item.molecule} to cart`,
        color: "success",
      },
    })
    let medicine = specialOrderPrescribedMedicineChoices.find(
      (medicine) => medicine?.sku === medicineList[0]?.productTitle
    )
    setDiagnoses(medicine?.illnesses)
    setFollowUpQuestions(medicine?.followUpQuestions || [])
  }

  const handleOnSelect = (item) => {
    if (isMedicineAlreadyInCart(item, state.epharmacy.medicines))
      dispatch({
        type: "SHOW_TOAST",
        payload: {
          message: `${item.productTitle} already in patient's cart`,
          color: "danger",
        },
      })
    else
      dispatch({
        type: "SHOW_MODAL",
        payload: {
          heading: "Add medicine to cart",
          isCard: true,
          headerClass: `has-text-primary has-background-light is-size-5`,
          content: (
            <AddMedicineModal
              addToCart={addMedicineToCart}
              medicine={item}
              isSpecialOrder
            />
          ),
        },
      })
  }

  const searchComponentProps = {
    placeholder: "Search medicines (i.e. Zytiga)",
    label: "Medicine Search",
    helper: {
      message:
        "Suggested Retail Price (SRP) does not reflect medicine assistance. Discount or free goods will be applied when you add your medicine to cart.",
    },
    dataSource: {
      type: "graphql",
      data: data.allProducts.nodes,
      lookupKeys: ["molecule", "brand"],
    },
    onSelect: handleOnSelect,
  }

  const handleSubmit = (values) => {
    const isEditing = locationState?.index >= 0
    const patientData = {
      ...values,
      patientName: values?.patientName,
      medicines: state?.epharmacy?.medicines,
      documents: state?.documents,
    }
    let patientList = [...state?.epharmacy?.patients]

    if (isEditing) patientList.splice(locationState?.index, 1, patientData)
    else patientList = [...patientList, patientData]

    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [],
        epharmacy: {
          ...state.epharmacy,
          medicines: [],
          patients: patientList,
        },
      },
    })
    dispatch({
      type: "SHOW_TOAST",
      payload: {
        message: isEditing
          ? `Successfully updated ${values?.patientName}'s order`
          : `Added ${values?.patientName} to list`,
        color: "success",
      },
    })
    navigate("/special-order")
  }

  const handleClickBack = () => {
    dispatch({
      type: "SAVE_CONTEXT_TO_SESSION",
      payload: {
        ...state,
        documents: [],
        epharmacy: {
          ...state.epharmacy,
          medicines: [],
        },
      },
    })
    navigate("/special-order")
  }

  const getMedicineDiagnoses = ({ medicines }) => {
    let medicine
    if (medicines?.length > 0)
      medicine = specialOrderPrescribedMedicineChoices.find(
        (medicine) => medicine?.sku === medicines[0]?.productTitle
      )
    setDiagnoses(medicine?.illnesses || [])
    setFollowUpQuestions(medicine?.followUpQuestions || [])
  }

  const handleDeleteMedicineCallback = ({ setFieldValue, medicines }) => {
    getMedicineDiagnoses({ medicines })
    setFieldValue("whyMedicineWasPrescribed", { label: "", value: "" })
  }

  useEffect(() => {
    getMedicineDiagnoses({ medicines })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Formik
      initialValues={
        locationState?.patientName
          ? { ...locationState }
          : { ...specialOrderPatientInitialValues }
      }
      onSubmit={handleSubmit}
      validationSchema={specialOrderValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <CartLayout
            isCentered
            desktop={8}
            fullhd={8}
            submit={{
              label: "Save",
              disabled: !medicines.length || !documents.length,
            }}
            cartContainer={
              <SpecialOrderCart
                handleDeleteMedicineCallback={({ medicines }) =>
                  handleDeleteMedicineCallback({ setFieldValue, medicines })
                }
              />
            }
            showCartInMobile
            showCartInTablet
            back={{
              label: "Back",
              callback: handleClickBack,
            }}
          >
            <UploadPrescription />
            <FormInput
              name="patientName"
              label="Patient"
              placeholder="Juan Dela Cruz"
              isRequired
            />
            <div className="mt-1 mb-2">
              <div className="is-hidden-mobile">
                <Search {...searchComponentProps} />
              </div>
              <div className="is-hidden-desktop is-hidden-tablet">
                <Link
                  to="/special-order/search"
                  state={{ ...locationState, ...values }}
                >
                  <Search {...searchComponentProps} isMobile />
                </Link>
              </div>
            </div>
            {noPrescriptionMessage}
          </CartLayout>
        </Form>
      )}
    </Formik>
  )
}

export default InputPatientDetails
