import React from "react"

const SCPWDDisclaimer = ({ discountType, hasSCPWDDiscount }) => {
  if (hasSCPWDDiscount && discountType === "SCPWD")
    return (
      <p className="mb-1 ml-1 is-size-7 has-text-weight-bold has-text-left">
        * All orders purchased with an SCPWD discount will not be eligible for
        the 6+2 medicine assistance.
      </p>
    )
  return null
}

export default SCPWDDisclaimer
