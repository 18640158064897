import React from "react"
import Img from "gatsby-image"
import Loading from "../../Elements/Loading"

const AddMedicineHelper = ({ image, hasSCPWDDiscount, calculatorState }) => {
  const {
    medicineQuantity,
    customerType,
    discountTier,
    isLoading,
    discountValues,
    discountType,
  } = calculatorState
  const hasSelected = customerType || discountType || discountTier
  const helperText = hasSelected
    ? "Input the total number of medicines you want to purchase"
    : `Select ${
        hasSCPWDDiscount
          ? "the type of discount you want to receive"
          : "if this is the first time you are ordering this medicine"
      }`

  if (medicineQuantity === 0 && hasSelected)
    return (
      <div className="mb-2">
        <div className="mt-2 mx-3 px-3 px-1-mobile mx-1-mobile">
          <Img fixed={image} />
          <p className="is-size-6 has-text-weight-bold">{helperText}</p>
        </div>

        {hasSelected && (isLoading || discountValues[0]?.notes) && (
          <article class="message mx-3 mt-1 is-light">
            <div class="message-body py-1">
              {isLoading ? <Loading size={2} /> : discountValues[0]?.notes}
            </div>
          </article>
        )}
      </div>
    )

  return null
}

export default AddMedicineHelper
