import React, { Fragment } from "react"

import Dropzone from "../../Upload/Dropzone"

const MAX_FILE_SIZE_IN_BYTES = 3145728
const MAX_FILE_COUNT = 2

const UploadPrescription = () => {
  return (
    <Fragment>
      <p className="is-size-6">Upload Prescription</p>
      <Dropzone
        maxByteSize={MAX_FILE_SIZE_IN_BYTES}
        maxFileCount={MAX_FILE_COUNT}
        docType="RX"
      />
      <article class="message is-light">
        <div class="message-body">
          For us to process your order, please indicate your patient's diagnosis
          in the prescription.
        </div>
      </article>
    </Fragment>
  )
}

export default UploadPrescription
