import React, { Fragment } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons"

import Button from "../../Elements/Button"
import FormInput from "../../Elements/Form/FormInput"

const MedicineQuantityInput = ({
  setFieldValue,
  changeQuantity,
  values,
  calculatorDispatch,
  calculatorState,
}) => {
  const { discountValues, maxQuantity } = calculatorState
  const hasDailyUseLimit = discountValues?.filter(
    (discountValue) => discountValue?.dailyUseLimit
  ).length
  const shouldBeHidden = !hasDailyUseLimit
  const shouldBeDisabled =
    (!values?.customerType && !values?.discountType && !values?.discountTier) ||
    !shouldBeHidden

  return (
    <Fragment>
      <p className="has-text-left has-text-grey is-size-6">
        You want to purchase:
      </p>
      <FormInput
        hideOptional
        disabled={shouldBeDisabled}
        hasAddons={shouldBeHidden}
        helper={
          <p className="help has-text-left has-text-grey">
            The maximum order quantity for this medicine is {maxQuantity || 10}.
          </p>
        }
        maxLength={2}
        onChange={(event) => {
          let medicineQuantity = parseInt(event.target.value)
          let finalQuantity =
            medicineQuantity > maxQuantity ? maxQuantity : medicineQuantity

          calculatorDispatch({
            type: "SET_MEDICINE_QUANTITY",
            payload: finalQuantity || 0,
          })
          setFieldValue("qty", finalQuantity)
        }}
        addonLeft={
          <Button
            disabled={shouldBeDisabled}
            onClick={() =>
              changeQuantity(setFieldValue, parseInt(values?.qty) - 1, () =>
                calculatorDispatch({
                  type: "SET_MEDICINE_QUANTITY",
                  payload: parseInt(values?.qty) - 1,
                })
              )
            }
            color="danger"
          >
            <FontAwesomeIcon icon={faMinus} />
          </Button>
        }
        addonRight={
          <Button
            disabled={shouldBeDisabled}
            onClick={() => {
              let medicineQuantity = parseInt(values?.qty) + 1

              changeQuantity(setFieldValue, medicineQuantity, () =>
                calculatorDispatch({
                  type: "SET_MEDICINE_QUANTITY",
                  payload: medicineQuantity,
                })
              )
            }}
            color="success"
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        }
        name="qty"
        className="has-text-centered"
        type="number"
        min={1}
      />
    </Fragment>
  )
}

export default MedicineQuantityInput
